<template>
  <div class="my-center">
    <Header>
      <i slot="left-one" class="me-icon-back" @click="$router.go(-1)"></i>
      <!-- <i slot="right-two" class="me-icon-share"></i> -->
      <i slot="right-one" class="me-icon-quit" @click="handlerLogout"></i>
    </Header>
    <div class="my-center__header">
      <Avatar :src="info.profile_photo" width="100px" height="100px" size="45px" />
      <div class="my-center__header--title">
        <span>{{info.name}}</span>
        <img
          v-if="info.country"
          class="country"
          :src="require(`../../assets/images/countrys/${info.country}.gif`)"
        />
      </div>
      <span v-html="'ID: ' + info.uc_id"></span>
    </div>
    <van-tabs v-model="active" color="#33cc66" class="my-center__tabs">
      <van-tab>
        <i slot="title" class="me-icon-document"></i>
        <div class="publish-btn" @click="$router.push({name:'publish',query:{type:'service'}})">
          <i class="me-icon-plus" />
        </div>
        <ul class="van-tabs__content--service" v-if="services.length">
          <li
            v-for="(item, index) in services"
            :key="index"
            @click="jumpDetailPage(item.id)"
          >
            <Thumb v-if="item.cover" :src="item.cover" width="100px" height="80px" />
            <div class="info">
              <span class="info__title" v-html="item.i_can_do"></span>
              <div class="info__tags" v-if="formartTags(item.tags).length">
                <van-tag
                  plain
                  type="success"
                  round
                  v-for="(tag, index) in formartTags(item.tags)"
                  :key="index"
                >{{tag}}</van-tag>
              </div>
              <p
                v-else
                class="info__desc"
                v-html="item.detail.length > 60 ? item.detail.slice(0,60) + '...' :item.detail"
              ></p>
            </div>
            <div class="btns">
              <i class="me-icon-edit" @click="handlerEditItemById($event,item.id)"></i>
              <i class="me-icon-delete" @click="handlerDeleteItemById($event,item.id)"></i>
            </div>
          </li>
        </ul>
        <div class="empty" v-else>
          <i class="me-icon-empty"></i>
        </div>
      </van-tab>
      <van-tab>
        <i slot="title" class="me-icon-group"></i>
        <div class="publish-btn" @click="$router.push({name:'publish',query:{type:'group',active}})">
          <i class="me-icon-plus" />
        </div>
        <ul class="van-tabs__content--service" v-if="groups.length">
          <li
            v-for="(item, index) in groups"
            :key="index"
            @click="jumpGroupPage(item.group_id)"
          >
            <Thumb :src="item.group_header" width="100px" height="80px" />
            <div class="info">
              <span class="info__title" v-html="item.group_name"></span>
              <div class="info__tags" v-if="formartTags(item.group_tag).length">
                <van-tag
                  plain
                  type="success"
                  round
                  v-for="(tag, index) in formartTags(item.group_tag)"
                  :key="index"
                >{{tag}}</van-tag>
              </div>
            </div>
            <div class="btns">
              <i class="me-icon-edit" @click="handlerEditItemById($event,item.group_id)"></i>
              <i class="me-icon-delete" @click="handlerDeleteItemById($event,item.group_id)"></i>
            </div>
          </li>
        </ul>
        <div class="empty" v-else>
          <i class="me-icon-empty"></i>
        </div>
      </van-tab>
      <van-tab>
        <i slot="title" class="me-icon-company"></i>
        <div class="publish-btn" @click="$router.push({name:'publish',query:{type:'company',active}})">
          <i class="me-icon-plus" />
        </div>
        <ul class="van-tabs__content--service" v-if="companys.length">
          <li
            v-for="(item, index) in companys"
            :key="index"
            @click="jumpEnterprisePage(item.enterprise_id)"
          >
            <Thumb :src="item.enterprise_logo" width="100px" height="80px" />
            <div class="info">
              <span class="info__title" v-html="item.enterprise_name"></span>
              <div class="info__tags" v-if="formartTags(item.enterprise_tag).length">
                <van-tag
                  plain
                  type="success"
                  round
                  v-for="(tag, index) in formartTags(item.enterprise_tag)"
                  :key="index"
                >{{tag}}</van-tag>
              </div>
            </div>
            <div class="btns">
              <i class="me-icon-edit" @click="handlerEditItemById($event,item.enterprise_id)"></i>
              <i class="me-icon-delete" @click="handlerDeleteItemById($event,item.enterprise_id)"></i>
            </div>
          </li>
        </ul>
        <div class="empty" v-else>
          <i class="me-icon-empty"></i>
        </div>
      </van-tab>
      <van-tab>
        <i slot="title" class="me-icon-myinfo"></i>
        <div class="van-tabs__content--userInfo">
          <div class="van-tabs__content--userInfo__tags" v-if="Object.keys(userInfo).length">
            <van-tag
              plain
              type="success"
              round
              v-for="(tag, index) in formartTags(userInfo.skills)"
              :key="index"
            >{{tag}}</van-tag>
          </div>
          <div
            class="van-tabs__content--userInfo__desc"
            v-if="userInfo.about_me"
            v-html="userInfo.about_me"
          ></div>
          <div
            class="van-tabs__content--userInfo__array"
            v-if="userInfo.education && userInfo.education.length"
          >
            <i class="me-icon-education"></i>
            <ul>
              <li class="item" v-for="(item, index) in userInfo.education" :key="index">
                <span class="item--title">{{item.school}} ({{item.field_of_study}})</span>
                <span class="item--time">{{item.time_str}} ({{item.degree_str}})</span>
                <span class="item--desc" v-if="item.description" v-html="item.description"></span>
              </li>
            </ul>
          </div>
          <div
            class="van-tabs__content--userInfo__array"
            v-if="userInfo.experiance && userInfo.experiance.length"
          >
            <i class="me-icon-express"></i>
            <ul>
              <li class="item" v-for="(item, index) in userInfo.experiance" :key="index">
                <span class="item--title">{{item.company_name}}({{item.title}})</span>
                <!-- <span class="item--time"></span> -->
                <span class="item--time">{{item.time_str}} {{item.location}}</span>
                <span class="item--desc" v-html="item.description"></span>
              </li>
            </ul>
          </div>
          <Zan :id="id" :count="userInfo.like_count" :type="3" />
          <Comments :id="id" :count="userInfo.reviewer_count" type="user"  ref="comments" @handlerReplay="handlerEmitReplay" @replayChild="handlerEmitReplayChild"/>
        </div>
      </van-tab>
    </van-tabs>
     <div class="replayInput" v-if="showReplay">
      <input v-model="replay" ref="replayInput" id="replayInput" v-focus="showReplay" />
      <van-button
        type="primary"
        icon="success"
        @click="isReplayChild ? handlerReplayChild() : handlerReplayFunc()"
      ></van-button>
    </div>
    <Tabbar v-else />
    <!-- <van-dialog v-model="show" show-cancel-button confirmButtonColor="#33cc66" @confirm="handlerDeleteitem()" :confirm-button-text="$t('done')" :cancel-button-text="$t('cancle')">
      <div class="tipContent" slot="default">
        <i class="me-icon-delete" />
        <i class="me-icon-question" />
      </div>
    </van-dialog>-->
  </div>
</template>
<script>
import Header from "@/components/business/h5/header";
import Avatar from "@/components/common/avatar";
import Thumb from "@/components/common/thumb";
import Zan from "@/components/business/h5/zan";
import Comments from "@/components/business/h5/comments";
import { Base64 } from "js-base64";
import {
  getUserInfo,
  getUserServerData,
  getUserGroups,
  getUserCompanys,
  getUserinfo_new,
  logout,
  deleteItemById,
  replayViews
} from "@/api/webApi";
import Tabbar from "@/components/common/tabbar";
import { formartTags } from "@/utils/help";
export default {
  name: "user",
  components: {
    Header,
    Thumb,
    Avatar,
    Zan,
    Tabbar,
    Comments
  },
  data() {
    return {
      id: "",
      language: "",
      info: {},
      active: 0,
      loginUser: {},
      pageNum: 1,
      pageSize: 10000,
      services: [],
      groups: [],
      companys: [],
      userInfo: {},
      show: false,
      showReplay:false,
      replay:''
    };
  },
  watch: {
    "$route.params.id": {
      handler() {
        window.location.reload();
      },
      deep: true
    },
    active: {
      handler(val) {
        this.pageNum = 1;
        this.pageSize = 10;
        this.handlerWatchActive(val);
      },
      deep: true
    }
  },
  created() {
    this.loginUser =
      localStorage.getItem("userinfo") !== null
        ? JSON.parse(localStorage.getItem("userinfo"))
        : {};
    this.id = this.$route.params.id || this.loginUser.id;
    this.language =
      localStorage.getItem("langCode") !== null
        ? localStorage.getItem("langCode")
        : "1";
    const {active} = this.$route.params        
    this.active = active ? parseInt(active) : 0
    this.handlerGetUserinfo();
    this.handlerWatchActive(this.active);
  },
  methods: {
    // 加密跳转页面
    jumpDetailPage(id) {
      this.$router.push({
        name: 'detail',
        params: { id: "pai_" + Base64.encode(id), type: 'service' }
      })
    },
    jumpEnterprisePage(id){
      this.$router.push({
        name: 'detail',
        params: { id: "pai_" + Base64.encode(id), type: 'enterprise' }
      })
    },
    jumpGroupPage(id) {
      this.$router.push({ 
        name: 'group', 
        params: { id: "pai_" + Base64.encode(id) } 
      })
    },
    jumpChatWithPage(name){
      this.$router.push({
        name:'chatWith',
        params:{
          id:"pai_" + Base64.encode(this.id),
          name:name,
          type:'chat'
        }
      })
    },
    handlerEmitReplay() {
      this.showReplay = !this.showReplay;
      this.isReplayChild = false;
      this.relpalChildObj = {};
    },
    handlerEmitReplayChild(obj) {
      this.showReplay = !this.showReplay;
      this.isReplayChild = true;
      this.relpalChildObj = obj;
    },
     async handlerReplayFunc() {
      const params = {
        reply: this.replay,
        language: this.language,
        operationId: this.id,
        operation: 3,
        userId: this.$store.state.User.userinfo.id,
        reviewId: ""
      };
      const result = await replayViews(params).catch(e => {});
      if (result && result.data) {
        this.showReplay = false;
        this.replay = "";
        this.$refs.comments.getComments();
      }
    },
    async handlerReplayChild() {
      const loginUser = this.$store.state.User.userinfo;
      if (loginUser && Object.keys(loginUser).length && loginUser.id) {
        const params = {
          ...this.relpalChildObj,
          reply: this.replay,
          userId: loginUser.id,
          token: loginUser.token,
          language: this.language
        };
        const result = await replayViews(params).catch(e => {});
        if (result && result.code == 200) {
          this.handlerEmitReplay();
          this.replay = "";
          this.$refs.comments.getComments();
        }
      } else {
        this.$router.push({
          name: "login",
          query: {
            redirect: this.$route.name,
            ...this.$route.params
          }
        });
      }
    },
    handlerEditItemById(event, id) {
      event.stopPropagation();
      let type = "";
      switch (this.active) {
        case 0:
          type = "service";
          break;
        case 1:
          type = "group";
          break;
        case 2:
          type = "company";
          break;
      }
      const query = {
        type,
        id,
        active:this.active
      };
      this.$router.push({ name: "publish", query });
    },
    handlerDeleteItemById(event, id) {
      event.stopPropagation();
      this.$dialog
        .confirm({
          message: ` <i class="me-icon-delete" /><i class="me-icon-question" />`,
          confirmButtonText: this.$t("done"),
          cancelButtonText: this.$t("cancle"),
          confirmButtonColor: "#33cc66"
        })
        .then(async () => {
          let type = "";
          const params = {
            userId: this.loginUser.id,
            language: this.language
          };
          // POST /rest/helogig/v3/delete
          switch (this.active) {
            case 0:
              type = "Helogig";
              params.helogigId = id;
              break;
            case 1:
              type = "Group";
              params.groupId = id;
              break;
            case 2:
              type = "Enterprise";
              params.enterpriseId = id;
              params.providerId = this.loginUser.id;
              delete params.userId;
              break;
          }
          const result = await deleteItemById(params, type);
          if (result && result.code === 200) {
            this.$toast.success();
            this.handlerWatchActive(this.active);
          } else {
            this.$toast.fail();
          }
        })
        .catch(() => {
          // this.$toast.fail();
        });
      // this.show = !this.show
    },
    handlerWatchActive(val = this.active) {
      switch (val) {
        case 0:
          this.handlerGetUserServiceData();
          break;
        case 1:
          this.handlerGetUserGroups();
          break;
        case 2:
          this.handlerGetUserCompanys();
          break;
        case 3:
          this.handlerGetUserinfoNew();
          break;
      }
    },
    formartTags,
    handlerLogout() {
      logout();            
      this.$store.dispatch('commitUserinfo',{})
      localStorage.removeItem('chatObj')
      localStorage.removeItem("userinfo");
      this.$router.push({ name: "home" });
    },
    async handlerGetUserinfoNew() {
      const params = {
        language: this.language,
        providerId: this.id,
        userId: this.loginUser.id || ""
      };
      const result = await getUserinfo_new(params).catch(e => {});
      if (result && result.data) this.userInfo = result.data[0];
      console.log(result, "/user");
    },
    async handlerGetUserCompanys() {
      const params = {
        language: this.language,
        providerId: this.id,
        userId: this.loginUser.id || "",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        categoryId: ""
      };
      const result = await getUserCompanys(params).catch(e => {});
      if (result && result.data) this.companys = result.data;
    },
    async handlerGetUserGroups() {
      const params = {
        language: this.language,
        providerId: this.id,
        userId: this.loginUser.id || "",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        groupCountMode: "all"
      };
      const result = await getUserGroups(params).catch(e => {});
      if (result && result.data) this.groups = result.data;
    },
    async handlerGetUserServiceData() {
      const params = {
        language: this.language,
        providerId: this.id,
        userId: this.loginUser.id || "",
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      const result = await getUserServerData(params).catch(e => {});
      if (result && result.data) this.services = result.data;
    },
    async handlerGetUserinfo() {
      const params = {
        userId: this.id,
        language: this.language
      };
      const result = await getUserInfo(params).catch(e => {});
      if (result && result.data) {
        this.info = result.data;
      }
    }
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/index';
.replayInput {
  width: 100%;
  padding: 0.5rem 0;
  background: #f5f5f5;
  position: fixed;
  border: 0;
  left: 0;
  bottom: 0px;
  display: flex;
  flex-flow: row;

  input {
    border: 1px solid #f5f5f5;
    border-radius: 3rem;
    min-height: 40px;
    margin: 0 0.5rem 0 0.9rem;
    text-indent: 0.6rem;
    flex: 1;
  }

  button {
    height: 40px;
    line-height: 40px;
    margin-right: 0.9rem;
    border-radius: 5rem;
    min-width: 60px;
  }
}

.van-dialog__message {
  padding: 20px 0;
  line-height: 60px;
  text-align: center;

  i {
    color: #999999;

    &.me-icon-delete {
      font-size: 36px;
    }

    &.me-icon-question {
      font-size: 24px;
    }
  }
}

+b(my-center) {
  margin: 54px 0;

  .chatBtn {
    background: #ffffff;
    color: #ffffff;
    border-top: 1px solid #e5e5e5;
    // fonts: 22px;
    position: fixed;
    width: 100%;
    padding: 0.5rem 0;
    bottom: 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    .btn {
      background: #33cc66;
      border-radius: 3rem;
      min-width: 40%;
      margin: 0 0.25rem;
      max-width: auto !important;
      padding: 0.4rem;
      text-align: center;

      &.default {
        color: #a5a5a5;
        background: none;
        border: 1px solid #e0e0e0;
      }

      // margin: 0 auto;
      i {
        font-size: 26px;
      }
    }
  }

  +e(header) {
    background: #33cc66;
    padding: 2rem 0.9rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    color: #ffffff;

    +m(title) {
      display: flex;
      flex-flow: row;
      margin: 0.5rem 0;
      align-items: center;

      .country {
        // width: 20px;
        height: 12px;
        object-fit: cover;
        margin-left: 0.3rem;
      }
    }
  }

  +e(tabs) {
    .van-tabs__wrap {
      height: 54px !important;
      border-bottom: 6px solid #f5f5f5;

      .van-tabs__nav {
        display: flex;
        flex-flow: row;

        .van-tabs__line {
          width: 25% !important;
        }

        .van-tab {
          width: 25%;
          border-right: 2px solid #f5f5f5;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          &:last-child {
            border-right: 0;
          }

          &.van-tab--active {
            color: #33cc66;
          }

          i {
            font-size: 24px;
          }
        }
      }
    }

    .van-tabs__content {
      background: #ffffff;

      .empty {
        font-size: 50px;
        color: #c5c5c5;
        padding-top: 6rem;
      }

      +m(userInfo) {
        display: flex;
        flex-flow: column;

        +e(tags) {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          padding: 0.9rem;

          span {
            margin: 0 0.3rem 0.3rem 0;
          }
        }

        +e(desc) {
          font-size: 14px;
          line-height: 24px;
          text-align: left;
          padding: 0.9rem;
        }

        +e(array) {
          display: flex;
          flex-flow: column;
          text-align: left;
          padding: 0.9rem;

          [class*='me-icon'] {
            font-size: 22px;
            color: #333333;
          }

          ul {
            +b(item) {
              display: flex;
              flex-flow: column;
              text-align: left;
              margin: 0.9rem 0;

              +m(title) {
                font-size: 15px;
                font-weight: bold;
                margin: 0.25rem 0;
                color: #333333;
              }

              +m(time) {
                font-size: 12px;
                color: #999999;
              }

              +m(desc) {
                margin: 0.25rem 0;
                font-size: 14px;
                line-height: 24px;
                color: #666666;
              }
            }
          }
        }
      }

      // padding: 0.9rem;
      +m(service) {
        li {
          display: flex;
          flex-flow: row;
          margin-bottom: 0.5rem;
          border-bottom: 1px solid #f5f5f5;
          padding: 0.9rem;

          +b(btns) {
            i {
              font-size: 18px;

              &:first-child {
                margin: 0 0.9rem;
              }
            }
          }

          +b(info) {
            text-align: left;
            flex: 1;

            +e(title) {
              font-size: 15px;
              font-weight: bold;
              margin-bottom: 0.5rem;
            }

            +e(tags) {
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              padding: 0.5rem 0;

              span {
                margin: 0 0.25rem 0.25rem 0;
              }
            }

            +e(desc) {
              font-size: 14px;
              line-height: 20px;
              margin: 0.25rem 0;
            }
          }
        }
      }
    }
  }
}
</style>